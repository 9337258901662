import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import { useNavigate } from '@reach/router'

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero title="Not Found" className="four-oh-four" />
      <main className="container page--four-oh-four text--center">
        <h1>We couldn't find what you're looking for</h1>
        <iframe
          title="four oh four"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/KqciS27w4lc"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullscreen
        />
        <p>
          <button onClick={() => navigate('/')} className="btn btn--black">
            Back to Home Page
          </button>
        </p>
      </main>
    </Layout>
  )
}

export default NotFoundPage
